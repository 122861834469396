<template></template>

<script>
import * as XLSX from "xlsx";

import whApi from "@/api/warehouse/";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import numbro from "numbro";

export default {
  name: "app",
  props: {
    dataExportExcel: Object,
    sumAmount: Array,
  },
  data() {
    return {
      jsonExport: [],

      mainProduct: [],
      subProduct: [],

      Heading: [],

      // loading: false
    };
  },

  async created() {
    // await this.getAllMainPd();
    // await this.getAllSubPd();
    await this.setHeader();
    await this.groupDateExport();
    // this.onExport();
  },

  methods: {
    setHeader() {
      this.Heading.push([
        "",
        this.formatDateHeader(this.dataExportExcel[0][0]._d),
        "",
        this.formatDateHeader(this.dataExportExcel[0][1]._d),
        "",
        this.formatDateHeader(this.dataExportExcel[0][2]._d),
        "",
        this.formatDateHeader(this.dataExportExcel[0][3]._d),
        "",
        this.formatDateHeader(this.dataExportExcel[0][4]._d),
        "",
        this.formatDateHeader(this.dataExportExcel[0][5]._d),
        "",
        this.formatDateHeader(this.dataExportExcel[0][6]._d),
        "",
        "",
        "",
      ]);

      this.Heading.push([
        "",
        this.dataExportExcel[1].title1,
        "",
        this.dataExportExcel[1].title2,
        "",
        this.dataExportExcel[1].title3,
        "",
        this.dataExportExcel[1].title4,
        "",
        this.dataExportExcel[1].title5,
        "",
        this.dataExportExcel[1].title6,
        "",
        this.dataExportExcel[1].title7,
        "",
        "",
        "",
      ]);

      this.Heading.push([
        "Category",
        "Sales",
        "%",
        "Sales",
        "%",
        "Sales",
        "%",
        "Sales",
        "%",
        "Sales",
        "%",
        "Sales",
        "%",
        "Sales",
        "%",
        "Total Week",
        "%Total",
      ]);
    },

    groupDateExport() {
      this.dataExportExcel[2].forEach((element, index) => {
        this.jsonExport.push({
          Category: element.category,
          Sales1: this.formatPrice(element.sales1),
          percent1: this.formatPrice(element.percent1),
          Sales2: this.formatPrice(element.sales2),
          percent2: this.formatPrice(element.percent2),
          Sales3: this.formatPrice(element.sales3),
          percent3: this.formatPrice(element.percent3),
          Sales4: this.formatPrice(element.sales4),
          percent4: this.formatPrice(element.percent4),
          Sales5: this.formatPrice(element.sales5),
          percent5: this.formatPrice(element.percent5),
          Sales6: this.formatPrice(element.sales6),
          percent6: this.formatPrice(element.percent6),
          Sales7: this.formatPrice(element.sales7),
          percent7: this.formatPrice(element.percent7),
          totalWeek: this.formatPrice(element.totalWeek),
          total: this.formatPrice(element.totalPercent),
        });

        if (index + 1 === this.dataExportExcel[2].length) {
          this.jsonExport.push({
            Category: "",
            Sales1: "",
            percent1: "",
            Sales2: "",
            percent2: "",
            Sales3: "",
            percent3: "",
            Sales4: "",
            percent4: "",
            Sales5: "",
            percent5: "",
            Sales6: "",
            percent6: "",
            Sales7: "",
            percent7: "",
            totalWeek: "",
            total: "",
          });

          this.sumAmount.forEach((element) => {
            this.jsonExport.push({
              Category: element.category,
              Sales1: this.formatPrice(element.item_amt1),
              percent1: "",
              Sales2: this.formatPrice(element.item_amt2),
              percent2: "",
              Sales3: this.formatPrice(element.item_amt3),
              percent3: "",
              Sales4: this.formatPrice(element.item_amt4),
              percent4: "",
              Sales5: this.formatPrice(element.item_amt5),
              percent5: "",
              Sales6: this.formatPrice(element.item_amt6),
              percent6: "",
              Sales7: this.formatPrice(element.item_amt7),
              percent7: "",
              totalWeek: this.formatPrice(
                element.item_amt1 +
                  element.item_amt2 +
                  element.item_amt3 +
                  element.item_amt4 +
                  element.item_amt5 +
                  element.item_amt6 +
                  element.item_amt7
              ),
              total: "",
            });
          });
        }
      });

      this.onExport();
    },

    formatDateHeader(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      }
    },

    formatPrice(val) {
      if (val) {
        if (typeof val === "string") {
          return "";
        }
        if (typeof val === "number") {
          const newVal = numbro(val).format({
            thousandSeparated: true,
            mantissa: 2,
          });
          return newVal;
        }
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    onExport() {
      const date = dayjs(new Date()).format("YYYY-MM-DD-HHMM");

      //Had to create a new workbook and then add the header
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, this.Heading);

      //Starting in the second row to avoid overriding and skipping headers
      XLSX.utils.sheet_add_json(ws, this.jsonExport, {
        origin: "A4",
        skipHeader: true,
      });

      XLSX.utils.book_append_sheet(wb, ws, "WeeklySales", true);

      XLSX.writeFile(wb, `WeeklySales-${date}.xlsx`);
    },
  },
};
</script>
