<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../../assets/build/vfs_fonts";

import apiConfig from "../../../../config/api";
import whApi from "@/api/warehouse/";

import moment from "moment";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

import numbro from "numbro";

export default {
  props: {
    dataExportPdf: Object,
    sumAmount: Array,
  },
  data: () => ({
    jsonExport: [],

    newDataTable: [],

    user: "",
    numberToTh: "",
  }),
  async created() {
    console.log(this.dataExportPdf, 123);
    await this.groupDateExport();
  },

  methods: {
    groupDateExport() {
      this.setDataToTable();
    },

    formatDateHeader(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      }
    },

    formatPrice(val) {
      if (val) {
        if (typeof val === "string") {
          return "";
        }
        if (typeof val === "number") {
          const newVal = numbro(val).format({
            thousandSeparated: true,
            mantissa: 2,
          });
          return newVal;
        }
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      const html = [
        {
          text: "รายงาน Weekly Report",
          fontSize: 16,
          bold: true,
          margin: [-30, 0, 0, 10],
        },

        // TABLE
        {
          table: {
            widths: [
              "*",
              52,
              "auto",
              52,
              "auto",
              52,
              "auto",
              52,
              "auto",
              52,
              "auto",
              52,
              "auto",
              52,
              "auto",
              53,
              "auto",
            ],
            body: this.newDataTable,
          },
          margin: [-30, 0, 0, 10],
        },
      ];

      const pdfData = {
        content: html,
        pageOrientation: "landscape",
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },

    setDataToTable() {
      this.dataExportPdf.forEach((element, index) => {
        if (index === 0) {
          this.newDataTable.push([
            {
              fontSize: 9,
              text: " ",
              border: [true, true, true, false],
              fillColor: "#c7c9c9",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.formatDateHeader(this.dataExportPdf[0][0]._d),
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.formatDateHeader(this.dataExportPdf[0][1]._d),
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.formatDateHeader(this.dataExportPdf[0][2]._d),
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.formatDateHeader(this.dataExportPdf[0][3]._d),
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.formatDateHeader(this.dataExportPdf[0][4]._d),
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.formatDateHeader(this.dataExportPdf[0][5]._d),
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.formatDateHeader(this.dataExportPdf[0][6]._d),
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              fillColor: "#c7c9c9",
              border: [true, true, false, false],
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              fillColor: "#c7c9c9",
              border: [false, true, true, false],
              alignment: "center",
            },
          ]);
        }

        if (index === 1) {
          this.newDataTable.push([
            {
              fontSize: 9,
              text: " ",
              fillColor: "#c7c9c9",
              border: [true, false, true, false],
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.dataExportPdf[1].title1,
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.dataExportPdf[1].title2,
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.dataExportPdf[1].title3,
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.dataExportPdf[1].title4,
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.dataExportPdf[1].title5,
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.dataExportPdf[1].title6,
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: this.dataExportPdf[1].title7,
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              border: [false, false, false, false],
              fillColor: "#c7c9c9",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: " ",
              border: [false, false, true, false],
              fillColor: "#c7c9c9",
              alignment: "center",
            },
          ]);
        }

        if (index === 2) {
          this.newDataTable.push([
            {
              fontSize: 9,
              text: "Category",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "Sales",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "%",
              fillColor: "#eaeced",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "Sales",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "%",
              fillColor: "#eaeced",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "Sales",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "%",
              fillColor: "#eaeced",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "Sales",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "%",
              fillColor: "#eaeced",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "Sales",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "%",
              fillColor: "#eaeced",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "Sales",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "%",
              fillColor: "#eaeced",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "Sales",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "%",
              fillColor: "#eaeced",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "Total Week",
              alignment: "center",
            },
            {
              fontSize: 9,
              text: "%Total",
              alignment: "center",
            },
          ]);
        }
      });

      this.dataExportPdf[2].forEach((element, index) => {
        if (index === 0) {
          this.newDataTable.push([
            {
              text:
                element.category.length > 12
                  ? element.category.substring(0, 12) + "..."
                  : element.category,
              fontSize: 8,
              alignment: "left",
            },
            {
              text: numbro(element.sales1).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              alignment: "right",
            },
            {
              text: numbro(element.percent1).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
            {
              text: numbro(element.sales2).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              alignment: "right",
            },
            {
              text: numbro(element.percent2).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
            {
              text: numbro(element.sales3).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              alignment: "right",
            },
            {
              text: numbro(element.percent3).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
            {
              text: numbro(element.sales4).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              alignment: "right",
            },
            {
              text: numbro(element.percent4).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
            {
              text: numbro(element.sales5).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              alignment: "right",
            },
            {
              text: numbro(element.percent5).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "center",
            },
            {
              text: numbro(element.sales6).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              alignment: "right",
            },
            {
              text: numbro(element.percent6).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
            {
              text: numbro(element.sales7).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              alignment: "right",
            },
            {
              text: numbro(element.percent7).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
            {
              text: numbro(element.totalWeek).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
            {
              text: numbro(element.totalPercent).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
          ]);
        } else {
          this.newDataTable.push([
            {
              text: element.category,
              fontSize: 8,
              fillColor:
                index + 1 === this.dataExportPdf[2].length ? "#eaeced" : "",
              alignment: "left",
            },
            {
              text: numbro(element.sales1).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor:
                index + 1 === this.dataExportPdf[2].length ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(element.percent1).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",

              alignment: "right",
            },
            {
              text: numbro(element.sales2).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor:
                index + 1 === this.dataExportPdf[2].length ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(element.percent2).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",

              alignment: "right",
            },
            {
              text: numbro(element.sales3).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor:
                index + 1 === this.dataExportPdf[2].length ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(element.percent3).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",

              alignment: "right",
            },
            {
              text: numbro(element.sales4).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor:
                index + 1 === this.dataExportPdf[2].length ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(element.percent4).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",

              alignment: "right",
            },
            {
              text: numbro(element.sales5).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor:
                index + 1 === this.dataExportPdf[2].length ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(element.percent5).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",

              alignment: "center",
            },
            {
              text: numbro(element.sales6).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor:
                index + 1 === this.dataExportPdf[2].length ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(element.percent6).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",

              alignment: "right",
            },
            {
              text: numbro(element.sales7).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor:
                index + 1 === this.dataExportPdf[2].length ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(element.percent7).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",

              alignment: "right",
            },
            {
              text: numbro(element.totalWeek).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
            {
              text: numbro(element.totalPercent).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 8,
              fillColor: "#eaeced",
              alignment: "right",
            },
          ]);
        }

        if (index + 1 === this.dataExportPdf[2].length) {
          this.newDataTable.push(
            [
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "center",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "center",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "center",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, true, false],

                alignment: "right",
              },
            ],
            [
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "center",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "center",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "center",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, false],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, true, false],

                alignment: "right",
              },
            ],
            [
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "center",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "center",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "center",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, false, true],

                alignment: "right",
              },
              {
                text: "",
                fontSize: 8,
                border: [true, false, true, true],

                alignment: "right",
              },
            ]
          );
        }
      });

      this.handleFooter();
    },

    handleFooter() {
      this.sumAmount.forEach((element, index) => {
        this.newDataTable.push([
          {
            text: index === 1 ? "Sales / Bill" : element.category,
            fontSize: 8,
            alignment: "left",
          },
          {
            text: this.formatPrice(element.item_amt1),
            fontSize: 8,
            alignment: "right",
          },
          {
            text: "",
            fontSize: 8,
            fillColor: "#eaeced",
            alignment: "right",
          },
          {
            text: this.formatPrice(element.item_amt2),
            fontSize: 8,
            alignment: "right",
          },
          {
            text: "",
            fontSize: 8,
            fillColor: "#eaeced",
            alignment: "right",
          },
          {
            text: this.formatPrice(element.item_amt3),
            fontSize: 8,
            alignment: "right",
          },
          {
            text: "",
            fontSize: 8,
            fillColor: "#eaeced",
            alignment: "right",
          },
          {
            text: this.formatPrice(element.item_amt4),
            fontSize: 8,
            alignment: "right",
          },
          {
            text: "",
            fontSize: 8,
            fillColor: "#eaeced",
            alignment: "right",
          },
          {
            text: this.formatPrice(element.item_amt5),
            fontSize: 8,
            alignment: "right",
          },
          {
            text: "",
            fontSize: 8,
            fillColor: "#eaeced",
            alignment: "right",
          },
          {
            text: this.formatPrice(element.item_amt6),
            fontSize: 8,
            alignment: "right",
          },
          {
            text: "",
            fontSize: 8,
            fillColor: "#eaeced",
            alignment: "right",
          },
          {
            text: this.formatPrice(element.item_amt7),
            fontSize: 8,
            alignment: "right",
          },
          {
            text: "",
            fontSize: 8,
            fillColor: "#eaeced",
            alignment: "right",
          },
          {
            text: this.formatPrice(
              element.item_amt1 +
                element.item_amt2 +
                element.item_amt3 +
                element.item_amt4 +
                element.item_amt5 +
                element.item_amt6 +
                element.item_amt7
            ),
            fontSize: 8,
            fillColor: "#eaeced",
            alignment: "right",
          },
          {
            text: "",
            fontSize: 8,
            fillColor: "#eaeced",
            alignment: "right",
          },
        ]);
      });

      this.exports();
    },
  },
};
</script>
