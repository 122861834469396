<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">รายงานยอดขายตามหมวดหมู่</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
              id="closeBtn"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <div class="card">
              <div class="card-body p-0">
                <div class="mt-3 pb-4" v-if="isShow">
                  <apexchart
                    type="bar"
                    height="400"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

export default {
  props: {
    dialogChart1: Boolean,
    dataChart1: Array,
    category: Array,
  },

  setup(props) {
    const chartOptions = {
      fill: {
        colors: ["#8950FC"],
      },
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["40%"],
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    };

    const series = [
      {
        name: "มูลค่า",
        data: [],
      },
    ];

    return {
      chartOptions,
      series,
    };
  },

  data: () => ({
    // minMaxAvg: 0
    isShow: false,
  }),

  watch: {
    dialogChart1(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        this.handleData();
      }
    },
  },
  methods: {
    handleData() {
      this.isShow = false;
      this.resetDataChart();

      let newArrCate = [];

      this.category.forEach((element, index) => {
        newArrCate.push(element.main_product_group_name);

        for (let index2 = 0; index2 < this.dataChart1.length; index2++) {
          const element2 = this.dataChart1[index2];

          if (index === index2) {
            this.category[index] = {
              ...this.category[index],
              amount:
                parseFloat(element2.sales1) +
                parseFloat(element2.sales2) +
                parseFloat(element2.sales3) +
                parseFloat(element2.sales4) +
                parseFloat(element2.sales5) +
                parseFloat(element2.sales6) +
                parseFloat(element2.sales7),
            };

            this.series[0].data.push(this.category[index].amount);
          }
        }
      });

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: newArrCate,
          convertedCatToNumeric: false,
        },
      };

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },

    resetDataChart() {
      this.series[0].data = [];
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: [],
          convertedCatToNumeric: false,
        },
      };
    },

    close() {
      this.$emit("closeDialogChart1");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
