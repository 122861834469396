<template>
  <div v-if="loading && stateLoading">
    <Loader />
  </div>
  <div class="card" v-if="!loading && !stateLoading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        รายงาน Weekly Report
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light-primary btn-hover-scale me-3"
          style="font-size: 14px; font-weight: bold; height: 40px"
          @click="gotoExportFile()"
          @mouseenter="hoverABtn"
        >
          <i style="font-size: 16px" class="bi bi-box-arrow-up"></i>
          Export
        </button>
        <div v-if="hover3">
          <DialogExportFile
            :dialogExportFile="dialogExportFile"
            @closeDialogExportFile="closeDialogExportFile"
            @selectedTypeExportFile="selectedTypeExportFile"
          />
        </div>

        <button
          type="button"
          class="btn btn-sm btn-light-primary me-3"
          style="font-size: 14px; font-weight: bold"
          @mouseover="hoverChart2()"
          @click="openChart2()"
        >
          กราฟสัดส่วนยอดขาย
        </button>

        <button
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
          @mouseover="hoverChart1()"
          @click="openChart1()"
        >
          กราฟยอดขายตามหมวดหมู่
        </button>
      </div>
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <a-date-picker
          v-model:value="searchInput"
          picker="week"
          style="border-radius: 6px; height: 38px; width: 100%"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded border border-gray-300 table-row-bordered gy-2 gs-2"
          style="width: 100%"
        >
          <thead>
            <tr
              class="fw-bold fs-7 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th
                colspan="2"
                rowspan="2"
                style="
                  background-color: DarkGrey;
                  border-radius: 6px 0px 0px 0px;
                  border-bottom: 0px;
                "
              ></th>
              <th class="text-center header-table-report2">
                {{
                  this.weekItem[0]._d
                    ? formatDateHeader(this.weekItem[0]._d)
                    : ""
                }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{
                  this.weekItem[1]._d
                    ? formatDateHeader(this.weekItem[1]._d)
                    : ""
                }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{
                  this.weekItem[2]._d
                    ? formatDateHeader(this.weekItem[2]._d)
                    : ""
                }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{
                  this.weekItem[3]._d
                    ? formatDateHeader(this.weekItem[3]._d)
                    : ""
                }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{
                  this.weekItem[4]._d
                    ? formatDateHeader(this.weekItem[4]._d)
                    : ""
                }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{
                  this.weekItem[5]._d
                    ? formatDateHeader(this.weekItem[5]._d)
                    : ""
                }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{
                  this.weekItem[6]._d
                    ? formatDateHeader(this.weekItem[6]._d)
                    : ""
                }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th
                colspan="2"
                rowspan="2"
                style="
                  background-color: DarkGrey;
                  border-radius: 0px 6px 0px 0px;
                  border-bottom: 0px;
                "
              ></th>
            </tr>

            <tr
              class="fw-bold fs-7 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
              v-for="(item, index) in dateItem"
              :key="index"
            >
              <th class="text-center header-table-report2">
                {{ item.title1 }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{ item.title2 }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{ item.title3 }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{ item.title4 }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{ item.title5 }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{ item.title6 }}
              </th>
              <th class="text-center header-table-report2"></th>
              <th class="text-center header-table-report2">
                {{ item.title7 }}
              </th>
              <th class="text-center header-table-report2"></th>
            </tr>

            <tr
              class="fw-bold fs-7 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th
                colspan="2"
                class="text-center header-table-report2"
                style="border-top: 0px"
              >
                Category
              </th>
              <th class="text-center header-table-report2">Sales</th>
              <th
                class="text-center header-table-report2"
                style="background-color: #e9e9e9"
              >
                %
              </th>
              <th class="text-center header-table-report2">Sales</th>
              <th
                class="text-center header-table-report2"
                style="background-color: #e9e9e9"
              >
                %
              </th>
              <th class="text-center header-table-report2">Sales</th>
              <th
                class="text-center header-table-report2"
                style="background-color: #e9e9e9"
              >
                %
              </th>
              <th class="text-center header-table-report2">Sales</th>
              <th
                class="text-center header-table-report2"
                style="background-color: #e9e9e9"
              >
                %
              </th>
              <th class="text-center header-table-report2">Sales</th>
              <th
                class="text-center header-table-report2"
                style="background-color: #e9e9e9"
              >
                %
              </th>
              <th class="text-center header-table-report2">Sales</th>
              <th
                class="text-center header-table-report2"
                style="background-color: #e9e9e9"
              >
                %
              </th>
              <th class="text-center header-table-report2">Sales</th>
              <th
                class="text-center header-table-report2"
                style="background-color: #e9e9e9"
              >
                %
              </th>
              <th
                class="text-center header-table-report2"
                style="border-top: 0px"
              >
                Total Week
              </th>
              <th
                class="text-center header-table-report2"
                style="border-top: 0px"
              >
                %Total
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="dataTable.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr
              v-for="(item, index) in dataTable"
              :key="index"
              class="fs-7 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
              :style="
                index === dataTable.length - 1
                  ? 'background-color: #e9e9e9'
                  : ''
              "
            >
              <td
                class="text-start body-table-report2"
                colspan="2"
                :style="
                  index === dataTable.length - 1
                    ? 'min-width: 180px; max-width: 180px; font-weight: bold; font-size: 16px'
                    : 'min-width: 180px; max-width: 180px; font-weight: 500'
                "
              >
                {{ item.category }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
              >
                {{ formatPrice(item.sales1) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
                :style="
                  index === dataTable.length - 1
                    ? ''
                    : index / 2 !== 0
                    ? 'background-color: #e9e9e9'
                    : index / 2 === 0
                    ? 'background-color: #e9e9e9'
                    : ''
                "
              >
                {{ formatPrice(item.percent1) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
              >
                {{ formatPrice(item.sales2) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
                :style="
                  index === dataTable.length - 1
                    ? ''
                    : index / 2 !== 0
                    ? 'background-color: #e9e9e9'
                    : index / 2 === 0
                    ? 'background-color: #e9e9e9'
                    : ''
                "
              >
                {{ formatPrice(item.percent2) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
              >
                {{ formatPrice(item.sales3) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
                :style="
                  index === dataTable.length - 1
                    ? ''
                    : index / 2 !== 0
                    ? 'background-color: #e9e9e9'
                    : index / 2 === 0
                    ? 'background-color: #e9e9e9'
                    : ''
                "
              >
                {{ formatPrice(item.percent3) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
              >
                {{ formatPrice(item.sales4) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
                :style="
                  index === dataTable.length - 1
                    ? ''
                    : index / 2 !== 0
                    ? 'background-color: #e9e9e9'
                    : index / 2 === 0
                    ? 'background-color: #e9e9e9'
                    : ''
                "
              >
                {{ formatPrice(item.percent4) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
              >
                {{ formatPrice(item.sales5) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
                :style="
                  index === dataTable.length - 1
                    ? ''
                    : index / 2 !== 0
                    ? 'background-color: #e9e9e9'
                    : index / 2 === 0
                    ? 'background-color: #e9e9e9'
                    : ''
                "
              >
                {{ formatPrice(item.percent5) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
              >
                {{ formatPrice(item.sales6) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
                :style="
                  index === dataTable.length - 1
                    ? ''
                    : index / 2 !== 0
                    ? 'background-color: #e9e9e9'
                    : index / 2 === 0
                    ? 'background-color: #e9e9e9'
                    : ''
                "
              >
                {{ formatPrice(item.percent6) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
              >
                {{ formatPrice(item.sales7) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
                :style="
                  index === dataTable.length - 1
                    ? ''
                    : index / 2 !== 0
                    ? 'background-color: #e9e9e9'
                    : index / 2 === 0
                    ? 'background-color: #e9e9e9'
                    : ''
                "
              >
                {{ formatPrice(item.percent7) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
                style="
                  min-width: 150;
                  max-width: 150px;
                  background-color: #e9e9e9;
                "
              >
                {{ formatPrice(item.totalWeek) }}
              </td>
              <td
                :class="
                  index === dataTable.length - 1
                    ? 'text-end body-table-report2 pt-3 fw-bold fs-6'
                    : 'text-end body-table-report2 '
                "
                style="
                  min-width: 50;
                  max-width: 100px;
                  background-color: #e9e9e9;
                "
              >
                {{ formatPrice(item.totalPercent) }}
              </td>
            </tr>

            <tr
              class="fs-7 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
              style="height: 28px"
            >
              <td
                class="text-start body-table-report2"
                colspan="2"
                style="min-width: 180px; max-width: 180px; font-weight: 500"
              ></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td class="text-end body-table-report2"></td>
              <td
                class="text-end body-table-report2"
                style="min-width: 150; max-width: 150px"
              ></td>
              <td
                class="text-end body-table-report2"
                style="min-width: 30; max-width: 30px"
              ></td>
            </tr>

            <tr
              v-for="(item, index) in sumAmount"
              :key="index"
              class="fs-7 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <td
                class="text-start body-table-report2"
                colspan="2"
                style="min-width: 180px; max-width: 180px; font-weight: 500"
              >
                {{ item.category }}
              </td>
              <td class="text-end body-table-report2">
                {{ formatPrice(item.item_amt1) }}
              </td>
              <td
                class="text-end body-table-report2"
                style="background-color: #e9e9e9"
              ></td>
              <td class="text-end body-table-report2">
                {{ formatPrice(item.item_amt2) }}
              </td>
              <td
                class="text-end body-table-report2"
                style="background-color: #e9e9e9"
              ></td>
              <td class="text-end body-table-report2">
                {{ formatPrice(item.item_amt3) }}
              </td>
              <td
                class="text-end body-table-report2"
                style="background-color: #e9e9e9"
              ></td>
              <td class="text-end body-table-report2">
                {{ formatPrice(item.item_amt4) }}
              </td>
              <td
                class="text-end body-table-report2"
                style="background-color: #e9e9e9"
              ></td>
              <td class="text-end body-table-report2">
                {{ formatPrice(item.item_amt5) }}
              </td>
              <td
                class="text-end body-table-report2"
                style="background-color: #e9e9e9"
              ></td>
              <td class="text-end body-table-report2">
                {{ formatPrice(item.item_amt6) }}
              </td>
              <td
                class="text-end body-table-report2"
                style="background-color: #e9e9e9"
              ></td>
              <td class="text-end body-table-report2">
                {{ formatPrice(item.item_amt7) }}
              </td>
              <td
                class="text-end body-table-report2"
                style="background-color: #e9e9e9"
              ></td>
              <td
                class="text-end body-table-report2"
                style="
                  min-width: 150;
                  max-width: 150px;
                  background-color: #e9e9e9;
                "
              >
                {{
                  formatPrice(
                    item.item_amt1 +
                      item.item_amt2 +
                      item.item_amt3 +
                      item.item_amt4 +
                      item.item_amt5 +
                      item.item_amt6 +
                      item.item_amt7
                  )
                }}
              </td>
              <td
                class="text-end body-table-report2"
                style="
                  min-width: 30;
                  max-width: 30px;
                  background-color: #e9e9e9;
                "
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="hover1">
      <Chart1
        :dialogChart1="dialogChart1"
        :dataChart1="dataChart1"
        :category="category"
        @closeDialogChart1="closeDialogChart1"
      />
    </div>

    <div v-if="hover2">
      <Chart2
        :dialogChart2="dialogChart2"
        :dataChart2="dataChart2"
        :category="category"
        @closeDialogChart2="closeDialogChart2"
      />
    </div>

    <div v-if="isExcel">
      <ExportExcelFile
        :dataExportExcel="dataExportExcel"
        :sumAmount="sumAmount"
      />
    </div>

    <div v-if="isPdf">
      <ExportPdfFile :dataExportPdf="dataExportPdf" :sumAmount="sumAmount" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

import reportApi from "@/api/report/";

import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

import { dataReport2 } from "@/core/report/report2/dataReport2";
import { filterSalesByWeek } from "@/utils/reports/report2/filterReport2";

import Chart1 from "./components/chart1.vue";
import Chart2 from "./components/chart2.vue";
import DialogExportFile from "./components/dialogExportFile.vue";
import ExportExcelFile from "./components/exportFileExcel.vue";
import ExportPdfFile from "./components/exportFilePdf.vue";

import { Mutations } from "@/store/enums/StoreEnums";
import { mapState } from "vuex";

export default {
  components: {
    DialogExportFile,
    ExportExcelFile,
    ExportPdfFile,
    Pagination,
    Search,
    Loader,
    Chart1,
    Chart2,
  },
  setup() {
    document.title = "BELUCA | รายงาน Weekly Report";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    companyItems: [],

    showing1: null,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],

    dateFormat: "YYYY/MM/DD",

    weekItem: [],
    weekItem1: [],
    dateItem: [],

    dataTable: [],

    category: [],

    dataChart1: [],
    dataChart2: [],

    dialogChart1: false,
    dialogChart2: false,

    hover1: false,
    hover2: false,
    hover3: false,

    sumAmount: [
      {
        category: "จำนวนบิล",
        item_amt1: 0,
        item_amt2: 0,
        item_amt3: 0,
        item_amt4: 0,
        item_amt5: 0,
        item_amt6: 0,
        item_amt7: 0,
      },
      {
        category: "ยอดขายเฉลี่ยต่อบิล",
        item_amt1: 0,
        item_amt2: 0,
        item_amt3: 0,
        item_amt4: 0,
        item_amt5: 0,
        item_amt6: 0,
        item_amt7: 0,
      },
      {
        category: "จำนวนชิ้นสินค้า",
        item_amt1: 0,
        item_amt2: 0,
        item_amt3: 0,
        item_amt4: 0,
        item_amt5: 0,
        item_amt6: 0,
        item_amt7: 0,
      },
      {
        category: "จำนวนชิ้นต่อบิล",
        item_amt1: 0,
        item_amt2: 0,
        item_amt3: 0,
        item_amt4: 0,
        item_amt5: 0,
        item_amt6: 0,
        item_amt7: 0,
      },
    ],

    // data by day
    salesDay1: 0,
    salesDay2: 0,
    salesDay3: 0,
    salesDay4: 0,
    salesDay5: 0,
    salesDay6: 0,
    salesDay7: 0,

    totalWeek: 0,

    // data by category
    cate1: 0,
    cate2: 0,
    cate3: 0,
    cate4: 0,
    cate5: 0,
    cate6: 0,
    cate7: 0,

    totalCate: 0,

    //export
    dialogImportExcel: false,

    dialogExportFile: false,

    dataExportExcel: [],
    isExcel: false,

    dataExportPdf: [],
    isPdf: false,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    loadingExport1: false,
  }),

  computed: {
    ...mapState({
      stateLoading: (state) => state.MockItems.isLoading,
    }),
  },

  async created() {
    await this.setDateThisWeek();
    await this.getMainPd();
    await this.search("created");
  },

  methods: {
    setDateThisWeek() {
      this.searchInput = dayjs(new Date());
      this.handleDate();
    },

    // set table by main PD
    async getMainPd(method) {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await whApi.mainProduct.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        method ? (this.dataTable = []) : "";
        method ? this.resetSum() : "";
        this.setDataTable(responseData.data);
        this.category = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    resetSum() {
      this.sumAmount = [
        {
          category: "จำนวนบิล",
          item_amt1: 0,
          item_amt2: 0,
          item_amt3: 0,
          item_amt4: 0,
          item_amt5: 0,
          item_amt6: 0,
          item_amt7: 0,
        },
        {
          category: "ยอดขายเฉลี่ยต่อบิล",
          item_amt1: 0,
          item_amt2: 0,
          item_amt3: 0,
          item_amt4: 0,
          item_amt5: 0,
          item_amt6: 0,
          item_amt7: 0,
        },
        {
          category: "จำนวนชิ้นสินค้า",
          item_amt1: 0,
          item_amt2: 0,
          item_amt3: 0,
          item_amt4: 0,
          item_amt5: 0,
          item_amt6: 0,
          item_amt7: 0,
        },
        {
          category: "จำนวนชิ้นต่อบิล",
          item_amt1: 0,
          item_amt2: 0,
          item_amt3: 0,
          item_amt4: 0,
          item_amt5: 0,
          item_amt6: 0,
          item_amt7: 0,
        },
      ];
    },

    setDataTable(items) {
      items.forEach((element) => {
        this.dataTable.push({
          category: element.main_product_group_name,

          sales1: 0,
          percent1: 0,

          sales2: 0,
          percent2: 0,

          sales3: 0,
          percent3: 0,

          sales4: 0,
          percent4: 0,

          sales5: 0,
          percent5: 0,

          sales6: 0,
          percent6: 0,

          sales7: 0,
          percent7: 0,

          totalWeek: 0,
          totalPercent: 0,
        });
      });
    },

    // set table by main PD

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    // set format header table
    formatDateHeader(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      }
    },

    formatPrice(val) {
      if (val) {
        if (typeof val === "string") {
          return "";
        }
        if (typeof val === "number") {
          const newVal = numbro(val).format({
            thousandSeparated: true,
            mantissa: 2,
          });
          return newVal;
        }
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    async handleDate() {
      const date = moment(this.searchInput.$d).startOf("week");

      if (date) {
        const day1 = moment(date);
        const day2 = moment(date).add(1, "days");
        const day3 = moment(date).add(2, "days");
        const day4 = moment(date).add(3, "days");
        const day5 = moment(date).add(4, "days");
        const day6 = moment(date).add(5, "days");
        const day7 = moment(date).add(6, "days");

        const newDay1 = moment(date).add(1, "days");
        const newDay2 = moment(date).add(2, "days");
        const newDay3 = moment(date).add(3, "days");
        const newDay4 = moment(date).add(4, "days");
        const newDay5 = moment(date).add(5, "days");
        const newDay6 = moment(date).add(6, "days");
        const newDay7 = moment(date).add(7, "days");

        this.weekItem = [day1, day2, day3, day4, day5, day6, day7];

        this.weekItem1 = [
          newDay1,
          newDay2,
          newDay3,
          newDay4,
          newDay5,
          newDay6,
          newDay7,
        ];

        // console.log(this.weekItem);
        const dateOfWeek = moment(date).day();

        // send to utils for calculate date
        this.dateItem = await dataReport2(dateOfWeek);
      }
    },
    // set format header table

    // handle search
    async search(created) {
      this.hover3 = false;
      this.isExcel = false;
      this.isPdf = false;

      created ? "" : this.getMainPd("reset");
      this.handleDate();

      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");
      this.loading = true;
      this.$store.commit(Mutations.SET_LOADING, true);

      const responseSearch = await reportApi.sale.searchByWeek(
        {
          // search: this.weekItem,
          search: this.weekItem1,
        },
        {
          companyId: companyId,
          companyBranchId: companyBranchId,
        }
        // "1"
      );

      if (responseSearch.response_status === "SUCCESS") {
        // await this.setDataTableBySearch(responseSearch.data.newDate);
        const resSale = await this.setDataTableBySearch(
          responseSearch.data.newDate
        );
        const resPos = await this.setDataTableBySearchPos(
          responseSearch.data.newDatePos,
          "pos"
        );

        this.groupResponse(resSale, resPos);
      }
      this.loading = false;
      setTimeout(() => {
        this.$store.commit(Mutations.SET_LOADING, false);
      }, 500);
    },

    async setDataTableBySearch(items) {
      this.loading = true;

      let responseByFilter = [];

      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        // console.log({ element });
        // console.log({ index });
        if (element.length > 0) {
          // send to utils for calculate
          const response = await filterSalesByWeek(
            element,
            index,
            this.category
          );

          responseByFilter.push(response);
        }
      }

      // main fn set date to table
      // this.setDataToTable(responseByFilter);
      return responseByFilter;
    },

    async setDataTableBySearchPos(items, method) {
      this.loading = true;

      let responseByFilter = [];

      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (element.length > 0) {
          // send to utils for calculate
          const response = await filterSalesByWeek(
            element,
            index,
            this.category,
            method
          );
          responseByFilter.push(response);
        }
      }

      // main fn set date to table
      // this.setDataToTable(responseByFilter);
      return responseByFilter;
    },

    async groupResponse(itemSales, ItemPos) {
      let resSalesAndPos = [];

      if (itemSales.length > 0) {
        await itemSales.forEach((element) => {
          resSalesAndPos.push(element);
        });
      }

      if (ItemPos.length > 0) {
        await ItemPos.forEach((element) => {
          resSalesAndPos.push(element);
        });
      }

      await this.setDataToTable(resSalesAndPos);
    },

    // main fn set date to table
    async setDataToTable(itemResponse) {
      let arr = [];
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];
      let arr4 = [];
      let arr5 = [];
      let arr6 = [];

      itemResponse.forEach((element, index) => {
        element.forEach((element2) => {
          const mainProductId = element2.product.mainProductGroupId;
          const findIndexMainPd = this.category.findIndex(
            (data) => data.id === mainProductId
          );

          // push data by date index
          if (element2.indexDate === 0) {
            this.dataTable[findIndexMainPd] = {
              ...this.dataTable[findIndexMainPd],
              sales1:
                parseFloat(this.dataTable[findIndexMainPd].sales1) +
                parseFloat(element2.item_total_before_vat),
            };

            arr.push(element2.orderId);

            // new arr for remove dup arr
            const newArr = [...new Map(arr.map((m) => [m, m])).values()];

            this.sumAmount[0] = {
              ...this.sumAmount[0],
              item_amt1: newArr.length,
            };

            this.sumAmount[2] = {
              ...this.sumAmount[2],
              item_amt1: this.sumAmount[2].item_amt1 + element2.item_amt,
            };

            this.sumAmount[3] = {
              ...this.sumAmount[3],
              item_amt1:
                this.sumAmount[2].item_amt1 / this.sumAmount[0].item_amt1,
            };
          }

          if (element2.indexDate === 1) {
            this.dataTable[findIndexMainPd] = {
              ...this.dataTable[findIndexMainPd],
              sales2:
                parseFloat(this.dataTable[findIndexMainPd].sales2) +
                parseFloat(element2.item_total_before_vat),
            };

            arr1.push(element2.orderId);

            const newArr = [...new Map(arr1.map((m) => [m, m])).values()];

            this.sumAmount[0] = {
              ...this.sumAmount[0],
              item_amt2: newArr.length,
            };

            this.sumAmount[2] = {
              ...this.sumAmount[2],
              item_amt2: this.sumAmount[2].item_amt2 + element2.item_amt,
            };

            this.sumAmount[3] = {
              ...this.sumAmount[3],
              item_amt2:
                this.sumAmount[2].item_amt2 / this.sumAmount[0].item_amt2,
            };
          }

          if (element2.indexDate === 2) {
            this.dataTable[findIndexMainPd] = {
              ...this.dataTable[findIndexMainPd],
              sales3:
                parseFloat(this.dataTable[findIndexMainPd].sales3) +
                parseFloat(element2.item_total_before_vat),
            };

            arr2.push(element2.orderId);

            const newArr = [...new Map(arr2.map((m) => [m, m])).values()];

            this.sumAmount[0] = {
              ...this.sumAmount[0],
              item_amt3: newArr.length,
            };

            this.sumAmount[2] = {
              ...this.sumAmount[2],
              item_amt3: this.sumAmount[2].item_amt3 + element2.item_amt,
            };

            this.sumAmount[3] = {
              ...this.sumAmount[3],
              item_amt3:
                this.sumAmount[2].item_amt3 / this.sumAmount[0].item_amt3,
            };
          }

          if (element2.indexDate === 3) {
            this.dataTable[findIndexMainPd] = {
              ...this.dataTable[findIndexMainPd],
              sales4:
                parseFloat(this.dataTable[findIndexMainPd].sales4) +
                parseFloat(element2.item_total_before_vat),
            };

            arr3.push(element2.orderId);

            const newArr = [...new Map(arr3.map((m) => [m, m])).values()];

            this.sumAmount[0] = {
              ...this.sumAmount[0],
              item_amt4: newArr.length,
            };

            this.sumAmount[2] = {
              ...this.sumAmount[2],
              item_amt4: this.sumAmount[2].item_amt4 + element2.item_amt,
            };

            this.sumAmount[3] = {
              ...this.sumAmount[3],
              item_amt4:
                this.sumAmount[2].item_amt4 / this.sumAmount[0].item_amt4,
            };
          }

          if (element2.indexDate === 4) {
            this.dataTable[findIndexMainPd] = {
              ...this.dataTable[findIndexMainPd],
              sales5:
                parseFloat(this.dataTable[findIndexMainPd].sales5) +
                parseFloat(element2.item_total_before_vat),
            };

            arr4.push(element2.orderId);

            const newArr = [...new Map(arr4.map((m) => [m, m])).values()];

            this.sumAmount[0] = {
              ...this.sumAmount[0],
              item_amt5: newArr.length,
            };

            this.sumAmount[2] = {
              ...this.sumAmount[2],
              item_amt5: this.sumAmount[2].item_amt5 + element2.item_amt,
            };

            this.sumAmount[3] = {
              ...this.sumAmount[3],
              item_amt5:
                this.sumAmount[2].item_amt5 / this.sumAmount[0].item_amt5,
            };
          }

          if (element2.indexDate === 5) {
            this.dataTable[findIndexMainPd] = {
              ...this.dataTable[findIndexMainPd],
              sales6:
                parseFloat(this.dataTable[findIndexMainPd].sales6) +
                parseFloat(element2.item_total_before_vat),
            };

            arr5.push(element2.orderId);

            const newArr = [...new Map(arr5.map((m) => [m, m])).values()];

            this.sumAmount[0] = {
              ...this.sumAmount[0],
              item_amt6: newArr.length,
            };

            this.sumAmount[2] = {
              ...this.sumAmount[2],
              item_amt6: this.sumAmount[2].item_amt6 + element2.item_amt,
            };

            this.sumAmount[3] = {
              ...this.sumAmount[3],
              item_amt6:
                this.sumAmount[2].item_amt6 / this.sumAmount[0].item_amt6,
            };
          }

          if (element2.indexDate === 6) {
            this.dataTable[findIndexMainPd] = {
              ...this.dataTable[findIndexMainPd],
              sales7:
                parseFloat(this.dataTable[findIndexMainPd].sales7) +
                parseFloat(element2.item_total_before_vat),
            };

            arr6.push(element2.orderId);

            const newArr = [...new Map(arr6.map((m) => [m, m])).values()];

            this.sumAmount[0] = {
              ...this.sumAmount[0],
              item_amt7: newArr.length,
            };

            this.sumAmount[2] = {
              ...this.sumAmount[2],
              item_amt7: this.sumAmount[2].item_amt7 + element2.item_amt,
            };

            this.sumAmount[3] = {
              ...this.sumAmount[3],
              item_amt7:
                this.sumAmount[2].item_amt7 / this.sumAmount[0].item_amt7,
            };
          }
        });
      });

      // summary
      await this.summaryByDay();
      await this.sumPercentByDay();

      await this.summaryByCategory();

      this.loading = false;
      setTimeout(() => {
        this.$store.commit(Mutations.SET_LOADING, false);
      }, 500);
    },

    // summary by day
    async summaryByDay() {
      // map and reduce sales1 - sales7
      this.salesDay1 = this.dataTable
        .map((data) => data.sales1)
        .reduce((sum, sales1) => sum + sales1, 0);
      this.salesDay2 = this.dataTable
        .map((data) => data.sales2)
        .reduce((sum, sales2) => sum + sales2, 0);
      this.salesDay3 = this.dataTable
        .map((data) => data.sales3)
        .reduce((sum, sales3) => sum + sales3, 0);
      this.salesDay4 = this.dataTable
        .map((data) => data.sales4)
        .reduce((sum, sales4) => sum + sales4, 0);
      this.salesDay5 = this.dataTable
        .map((data) => data.sales5)
        .reduce((sum, sales5) => sum + sales5, 0);
      this.salesDay6 = this.dataTable
        .map((data) => data.sales6)
        .reduce((sum, sales6) => sum + sales6, 0);
      this.salesDay7 = this.dataTable
        .map((data) => data.sales7)
        .reduce((sum, sales7) => sum + sales7, 0);

      this.totalWeek =
        this.salesDay1 +
        this.salesDay2 +
        this.salesDay3 +
        this.salesDay4 +
        this.salesDay5 +
        this.salesDay6 +
        this.salesDay7;

      this.dataTable.push({
        category: "ยอดขายรวม",

        sales1: this.salesDay1,
        percent1: 100,

        sales2: this.salesDay2,
        percent2: 100,

        sales3: this.salesDay3,
        percent3: 100,

        sales4: this.salesDay4,
        percent4: 100,

        sales5: this.salesDay5,
        percent5: 100,

        sales6: this.salesDay6,
        percent6: 100,

        sales7: this.salesDay7,
        percent7: 100,

        totalWeek: this.totalWeek,
        totalPercent: 100,
      });

      this.salesAvg();
    },

    salesAvg() {
      // set avg sales per bill
      this.sumAmount[1] = {
        ...this.sumAmount[1],
        item_amt1:
          this.sumAmount[0].item_amt1 === 0
            ? 0
            : this.salesDay1 / this.sumAmount[0].item_amt1,
      };

      this.sumAmount[1] = {
        ...this.sumAmount[1],
        item_amt2:
          this.sumAmount[0].item_amt2 === 0
            ? 0
            : this.salesDay2 / this.sumAmount[0].item_amt2,
      };

      this.sumAmount[1] = {
        ...this.sumAmount[1],
        item_amt3:
          this.sumAmount[0].item_amt3 === 0
            ? 0
            : this.salesDay3 / this.sumAmount[0].item_amt3,
      };

      this.sumAmount[1] = {
        ...this.sumAmount[1],
        item_amt4:
          this.sumAmount[0].item_amt4 === 0
            ? 0
            : this.salesDay4 / this.sumAmount[0].item_amt4,
      };

      this.sumAmount[1] = {
        ...this.sumAmount[1],
        item_amt5:
          this.sumAmount[0].item_amt5 === 0
            ? 0
            : this.salesDay5 / this.sumAmount[0].item_amt5,
      };

      this.sumAmount[1] = {
        ...this.sumAmount[1],
        item_amt6:
          this.sumAmount[0].item_amt6 === 0
            ? 0
            : this.salesDay6 / this.sumAmount[0].item_amt6,
      };

      this.sumAmount[1] = {
        ...this.sumAmount[1],
        item_amt7:
          this.sumAmount[0].item_amt7 === 0
            ? 0
            : this.salesDay7 / this.sumAmount[0].item_amt7,
      };

      this.dataChart2 = this.dataTable;
      this.dataChart1 = this.dataTable;
    },

    async sumPercentByDay() {
      // loop calculate percent by day
      this.dataTable.forEach((element, index) => {
        this.dataTable[index].percent1 =
          (parseFloat(element.sales1) * 100) /
          parseFloat(this.salesDay1 ? this.salesDay1 : 1);

        this.dataTable[index].percent2 =
          (parseFloat(element.sales2) * 100) /
          parseFloat(this.salesDay2 ? this.salesDay2 : 1);

        this.dataTable[index].percent3 =
          (parseFloat(element.sales3) * 100) /
          parseFloat(this.salesDay3 ? this.salesDay3 : 1);

        this.dataTable[index].percent4 =
          (parseFloat(element.sales4) * 100) /
          parseFloat(this.salesDay4 ? this.salesDay4 : 1);

        this.dataTable[index].percent5 =
          (parseFloat(element.sales5) * 100) /
          parseFloat(this.salesDay5 ? this.salesDay5 : 1);

        this.dataTable[index].percent6 =
          (parseFloat(element.sales6) * 100) /
          parseFloat(this.salesDay6 ? this.salesDay6 : 1);

        this.dataTable[index].percent7 =
          (parseFloat(element.sales7) * 100) /
          parseFloat(this.salesDay7 ? this.salesDay7 : 1);

        // if want to add every percent summary === 100
        if (index === this.dataTable.length - 1) {
          this.dataTable[index].percent1 = 100;
          this.dataTable[index].percent2 = 100;
          this.dataTable[index].percent3 = 100;
          this.dataTable[index].percent4 = 100;
          this.dataTable[index].percent5 = 100;
          this.dataTable[index].percent6 = 100;
          this.dataTable[index].percent7 = 100;
        }
      });
    },
    // summary by day

    // summary by category
    async summaryByCategory() {
      let totalByCate = 0;

      this.dataTable.forEach((element, index) => {
        // total week
        totalByCate =
          parseFloat(element.sales1) +
          parseFloat(element.sales2) +
          parseFloat(element.sales3) +
          parseFloat(element.sales4) +
          parseFloat(element.sales5) +
          parseFloat(element.sales6) +
          parseFloat(element.sales7);

        this.dataTable[index].totalWeek = totalByCate;
        this.dataTable[index].totalPercent =
          this.totalWeek === 0
            ? 0
            : (parseFloat(totalByCate) * 100) / parseFloat(this.totalWeek);
      });

      this.dataExportPdf = [this.weekItem, ...this.dateItem, this.dataTable];
      this.dataExportExcel = [this.weekItem, ...this.dateItem, this.dataTable];
    },

    // chart 1
    openChart1() {
      this.dialogChart1 = true;
    },

    closeDialogChart1() {
      this.dialogChart1 = false;
    },

    hoverChart1() {
      this.hover1 = true;
      this.hover2 = false;
      this.hover3 = false;
    },

    // chart 2
    openChart2() {
      this.dialogChart2 = true;
    },

    closeDialogChart2() {
      this.dialogChart2 = false;
    },

    hoverChart2() {
      this.hover1 = false;
      this.hover2 = true;
      this.hover3 = false;
    },

    //export file excel, pdf
    gotoExportFile() {
      this.dialogExportFile = true;
      this.isExcel = false;
      this.isPdf = false;
    },

    closeDialogExportFile() {
      this.dialogExportFile = false;
    },

    selectedTypeExportFile(val) {
      this.dialogExportFile = false;
      if (val === "excel") {
        this.isExcel = true;
      } else {
        this.isPdf = true;
      }
    },

    hoverABtn() {
      this.hover1 = false;
      this.hover2 = false;
      this.hover3 = true;
    },
    //export file excel, pdf
  },
};
</script>

<style scoped src="@/assets/custom-outside.css"></style>
